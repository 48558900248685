import React from "react";
import { HeroSectionImg } from "../../assets/images";

const HeroSection = () => {
  return (
    <>
      <div id="home" className="hero-section">
        <div className="container pt-28 pb-20">
          <div className="max-w-[930px] mx-auto mb-14">
            <h1 className="text-heading1 text-white mb-6 text-center">
              Build, Deploy and Scale DApps in Web3 Effortlessly
            </h1>
            <p className="text-body text-white/90 text-center">
              Take your DApps to new heights with Kalki. We are the
              next-generation Web3 platform that empowers developers to create
              groundbreaking decentralized solutions. We strive to lead the
              revolution in software development for innovative and seamless
              integration of decentralized applications.
            </p>
          </div>
          {/* Graphics */}
          <img
            src={HeroSectionImg}
            alt=""
            className="w-full min-h-[420px] h-full object-cover rounded-xl"
          />
        </div>
      </div>
    </>
  );
};

export default HeroSection;
