import React from "react";
import { ServicesMobileImg, ServicesWeb3Img } from "../../assets/images";

const Services = () => {
  return (
    <>
      <div id="services" className="container py-24">
        <div className="grid md:grid-cols-2 gap-8">
          <div className="">
            <h4 className="text-heading4 text-white mb-8">
              We assist developers to build, deploy and scale comprehensive
              platforms by simpligying the process.
            </h4>
            <div className="p-6 bg-dark rounded-xl flex flex-col">
              <div className="h-full bg-slate-800 rounded-xl mb-6">
                <img
                  src={ServicesMobileImg}
                  alt=""
                  className="w-full h-full min-h-[200px] object-cover rounded-xl"
                />
              </div>
              <div className="text-heading5 text-white mb-3">
                Cutting-Edge Software Development
              </div>
              <p className="text-body text-white/90">
                Highly innovative solutions for developing software applications
                featuring both IOS and Andriod OS
              </p>
            </div>
          </div>
          <div className="p-6 bg-dark  rounded-xl flex flex-col gap-y-4">
            <div className="h-full bg-slate-800 rounded-xl mb-6">
              <img
                src={ServicesWeb3Img}
                alt=""
                className="w-full h-full min-h-[200px]  object-cover rounded-xl"
              />
            </div>
            <div className="text-heading5 text-white">
              Web3 Finance Toolkit: Revolutionary DApp Development
            </div>
            <p className="text-body text-white/90">
              Revolutionary Web3 software development tool-kits for DApps,
              primarily focusing on finance technologies including decentralized
              finance applications.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
