import React from "react";
import { AboutUsImg } from "../../assets/images";

const AboutUs = () => {
  return (
    <>
      <div id="about_us" className="container py-14">
        <div className="max-w-[930px] mx-auto mb-14">
          <h2 className="text-heading2 text-white text-center mb-4">
            About Us
          </h2>
          <p className="text-body text-white/90 text-center">
            Kalki: Empowering Web2 and Web3 Development with Streamlined Tools
            and a Focus on Decentralized Finance. Unleash the Potential of Web3
            with Kalki's All-in-One Platform for Effortless Development,
            Deployment, and Scaling of Applications.
          </p>
        </div>
        <div className="flex flex-col lg:flex-row gap-8">
          {/* Graphics */}
          <div className="lg:w-2/5 bg-slate-800 h-[380px] lg:h-auto rounded-xl">
            <img
              src={AboutUsImg}
              alt=""
              className="w-full h-full object-cover rounded-xl"
            />
          </div>
          {/* About Us */}
          <div className="lg:w-3/5 flex flex-col gap-y-4">
            <p className="text-body text-white/90">
              Kalki began in the year 2021, embarking on a journey to assist
              developers of Web2 and Web3 to achieve successful software
              development and deployment. Our passion for Web3 allows Kalki to
              focus on blockchain, smart contracts, and decentralized
              applications (DApps). Thus, we provide an all-in-one platform that
              streamlines the development and deployment process.
            </p>
            <div className="text-body text-white/90">
              Our team of well-rounded experienced developers and enthusiasts
              dedicate themselves to pushing the boundaries of innovation in the
              field of software development. Our set of comprehensive tools
              enables Web2 and Web3 developers to build, deploy and scale any
              available infrastructure and protocols.{" "}
            </div>
            <p className="text-body text-white/90">
              We intend to commit further to the development of financial
              technology, more specifically, the robust revolutionization of
              decentralized finance applications in the coming years. Kalki
              strives to provide extensive support for seamless integrations of
              DApps in the Web3 ecosystem and feature some of the top
              applications for mobile in the Web2 realm.{" "}
            </p>
            <p className="text-body-large text-white/80 font-medium">
              Unlock the power of Web3 and start building, right here with
              Kalki!{" "}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
