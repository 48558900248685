import React, { useState } from "react";
import { KalkiWhiteLogo } from "../../assets/images";
import { MenuIcon, CrossIcon } from "../../assets/icons";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [toggleMobileMenu, setToggleMobileMenu] = useState(false);

  return (
    <>
      <div className="container">
        <nav className="px-4 md:px-6 flex justify-between py-6">
          <Link to="/" className="">
            <img src={KalkiWhiteLogo} alt="" className="w-20" />
          </Link>
          <div className="md:hidden text-white">
            <button onClick={() => setToggleMobileMenu(true)} className="">
              <MenuIcon />
            </button>
          </div>
          <div className="hidden md:block">
            <div className="flex gap-6 text-white">
              <div className={`hover:text-primary`}>
                <a href="#home">Home</a>
              </div>
              <div className={`hover:text-primary`}>
                <a href="#about_us">About</a>
              </div>
              <div className={`hover:text-primary`}>
                <a href="#services">Services</a>
              </div>
              <div className={`hover:text-primary`}>
                <a href="#contact_us">Contact</a>
              </div>
            </div>
          </div>
        </nav>
      </div>
      {/* Mobile Menu */}
      <div
        className={`fixed z-50 h-screen w-screen bg-primary flex justify-between px-4 py-5 transition-all duration-300 top-0 ${
          toggleMobileMenu ? "left-0 opacity-100" : "-left-full opacity-0"
        }`}
      >
        <div className="flex flex-col gap-2  text-white pt-[240px]">
          <div className={``}>
            <a href="#home" onClick={() => setToggleMobileMenu(false)}>
              Home
            </a>
          </div>
          <div className={``}>
            <a href="#about_us" onClick={() => setToggleMobileMenu(false)}>
              About
            </a>
          </div>
          <div className={``}>
            <a href="#services" onClick={() => setToggleMobileMenu(false)}>
              Services
            </a>
          </div>
          <div className={``}>
            <a href="#contact_us" onClick={() => setToggleMobileMenu(false)}>
              Contact
            </a>
          </div>
        </div>
        <div className="">
          <button onClick={() => setToggleMobileMenu(false)} className="">
            <CrossIcon />
          </button>
        </div>
      </div>
    </>
  );
};

export default Navbar;
