import React from "react";
import HeroSection from "./HeroSection";
import Services from "./Services";
import AboutUs from "./AboutUs";
import ContactUs from "./ContactUs";

const HomePage = () => {
  return (
    <>
      <HeroSection />
      <AboutUs />
      <Services />
      <ContactUs />
    </>
  );
};

export default HomePage;
