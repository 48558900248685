import React from "react";
import { Link } from "react-router-dom";
import { KalkiIconLogo, KalkiWhiteLogo } from "../../assets/images";

const Footer = () => {
  return (
    <>
      <div className="border-t border-dark-light">
        <div className="container py-10 relative">
          <div className="flex flex-col md:flex-row justify-between items-center gap-6">
            <Link to="/" className="">
              <img src={KalkiWhiteLogo} alt="" className="w-24" />
            </Link>
            <div className="flex flex-col gap-6">
              <div className="flex gap-6">
                <a
                  href="#home"
                  className="text-body text-white hover:text-white/80"
                >
                  Home
                </a>
                <a
                  href="#about_us"
                  className="text-body text-white hover:text-white/80"
                >
                  About
                </a>
                <a
                  href="#services"
                  className="text-body text-white hover:text-white/80"
                >
                  Services
                </a>
                <a
                  href="#contact_us"
                  className="text-body text-white hover:text-white/80"
                >
                  Contact
                </a>
              </div>
              <p className="text-white/80 text-body-sm text-end">
                ©{new Date().getFullYear()} Kalki Finance
              </p>
            </div>
            <img
              src={KalkiIconLogo}
              alt=""
              className="absolute h-20 left-1/2 transform -translate-x-1/2"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
