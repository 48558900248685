import { Route, Routes } from "react-router-dom";
import Layout from "./components/layouts/Layout";
import HomePage from "./components/home";

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="*" element={<>Page Not Found</>} />
        </Route>
      </Routes>
    </>
  );
};

export default App;
