import React from "react";
import { ContactUsImg } from "../../assets/images";

const ContactUs = () => {
  return (
    <>
      <div id="contact_us" className="container pt-14 pb-24">
        <div className="max-w-[930px] mx-auto mb-14">
          <h2 className="text-heading2 text-white text-center mb-4">
            Contact Us
          </h2>
          <p className="text-body text-white/90 text-center">
            Get in touch with us and unlock the power of Web3 with Kalki today!
          </p>
        </div>
        <div className="flex flex-col md:flex-row gap-8 lg:gap-12">
          {/* Graphics */}
          <div className="md:w-1/2 bg-slate-700 rounded-xl h-[380px] md:h-auto">
            <img
              src={ContactUsImg}
              alt=""
              className="h-full object-cover rounded-xl"
            />
          </div>
          {/* About Us */}
          <div className="md:w-1/2 flex flex-col gap-y-6 relative">
            {/* <div className="flex gap-8 items-start">
              <div className="h-5 w-5 rounded-full border-2 border-primary z-10 bg-black  shrink-0"></div>
              <div className="flex flex-col gap-y-1.5">
                <h5 className=" text-heading5 text-white">Address</h5>
                <p className="text-body text-white/90">
                  208 S Lasky Drive, Unit 102, Beverly Hills, CA 90212
                </p>
              </div>
            </div>
            <div className="flex gap-8 items-start">
              <div className="h-5 w-5 rounded-full border-2 z-10 bg-black shrink-0"></div>
              <div className="flex flex-col gap-y-1.5">
                <h5 className=" text-heading5 text-white">Phone</h5>
                <a
                  href="tel:+13106146930"
                  className="text-body text-white/90 hover:text-primary"
                >
                  +1 310-614-6930
                </a>
              </div>
            </div> */}
            <div className="flex gap-8 items-start">
              <div className="h-5 w-5 rounded-full border-2 z-10 bg-black  shrink-0"></div>
              <div className="flex flex-col gap-y-1.5">
                <h5 className=" text-heading5 text-white">Mail</h5>
                <a
                  href="mailto:support@kalki.finance"
                  className="text-body hover:text-primary text-white/90"
                >
                  support@kalki.finance
                </a>
              </div>
            </div>
            <div className="absolute top-0 bottom-[20%] 2xl:bottom-[35%] left-[9px] w-0.5 bg-white/50"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
